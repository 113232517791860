import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

const getYoutubeVideoId = (url) => {
  const urlParams = new URLSearchParams(new URL(url).search);
  return urlParams.get('v');
};

const ShareSong = ({ token }) => {
  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [battleId, setBattleId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const isValidYouTubeUrl = (url) => {
    try {
      const videoId = getYoutubeVideoId(url);
      return videoId !== null && videoId !== '';
    } catch (error) {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidYouTubeUrl(url)) {
      setError('Please enter a valid YouTube URL.');
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/battle`,
        { url },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUrl('');
      setError('');
      setBattleId(response.data.battleId);
      setShowModal(true);
    } catch (err) {
      console.error('Error sharing song:', err);
      setError(err.response?.data?.message || 'An error occurred while sharing the track. Please try again.');
    }
  };

  const handleShareWithFriend = () => {
    const battleLink = `${window.location.origin}/battle/${battleId}`;
    navigator.clipboard.writeText(battleLink)
      .then(() => {
        alert('Battle link copied to clipboard! Now share it with a friend and see who wins!');
      })
      .catch(err => {
        console.error('Error copying link to clipboard:', err);
      });
  };

  const handleViewBattle = () => {
    navigate(`/battle/${battleId}`);
  };

  if (isLoading) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  if (!token) {
    return (
      <div className="container mt-5">
        <div className="alert alert-warning" role="alert">
          <h4 className="alert-heading">Login Required</h4>
          <p>You must be logged in to share a song. Please log in or create an account to continue.</p>
          <hr />
          <p className="mb-0">
            <Link to="/login" className="btn btn-primary me-2">Login</Link>
            <Link to="/signup" className="btn btn-secondary">Sign Up</Link>
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container my-3 my-md-5 px-3 px-md-0">
        <div className="p-4 p-md-5 text-center bg-light rounded-3">
          <h1 className="text-body-emphasis mb-3">Share a track!</h1>
          <p className="lead">Found a gem? Share it with the community and see how it stacks up in a match!</p>
        </div>
      </div>
      <div className="container px-3 px-md-0">
        <form onSubmit={handleSubmit} className="needs-validation mb-4" noValidate>
          <div className="mb-3">
            <label htmlFor="url" className="form-label">YouTube URL</label>
            <input
              type="text"
              className="form-control"
              id="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Enter YouTube URL"
              required
            />
            <div className="invalid-feedback">
              Please enter a valid YouTube URL.
            </div>
          </div>
          <button type="submit" className="btn btn-primary">Share</button>
        </form>
        <div className="alert alert-secondary mt-2" role="alert">
          <small>Sharing a track will require 1 point.</small>
        </div>
        {error && <div className="alert alert-danger mt-3">{error}</div>}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <div className="modal-content rounded-4 shadow">
          <div className="modal-header border-bottom-0">
            <h1 className="modal-title fs-5">Track shared! Your match is live.</h1>
            <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
          </div>
          <div className="modal-body py-0">
            <p>Invite friends to vote and increase your chances of winning.</p>
          </div>
          <div className="modal-footer flex-column align-items-stretch w-100 gap-2 pb-3 border-top-0">
            <Button variant="primary" onClick={handleShareWithFriend} className="btn-lg">
              Share with a Friend
            </Button>
            <Button variant="secondary" onClick={handleViewBattle} className="btn-lg">
              View match
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShareSong;