import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const LandingPage = () => {
  const [liveMatches, setLiveMatches] = useState([]);
  const [openMatches, setOpenMatches] = useState([]);
  const [pastMatches, setPastMatches] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchBattles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/battles`);
        const battles = response.data;

        // Sort battles in descending order (newest first)
        const sortedBattles = battles.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        const liveMatches = sortedBattles.filter(battle => battle.type === 'live');
        const openMatches = sortedBattles.filter(battle => battle.type === 'open');
        const pastMatches = sortedBattles.filter(battle => battle.type === 'past');

        console.log('Live matches:', liveMatches);
        console.log('Open matches:', openMatches);
        console.log('Past matches:', pastMatches);

        setLiveMatches(liveMatches);
        setOpenMatches(openMatches);
        setPastMatches(pastMatches);

      } catch (error) {
        console.error('Error fetching battles:', error); // Log the error to the console for debugging
        setMessage('Failed to fetch battles');
      }
    };

    fetchBattles();
  }, []);

  const formatTimeLeft = (votingEndsAt) => {
    const timeLeft = new Date(votingEndsAt) - new Date();
    const hours = Math.floor(timeLeft / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m left`;
  };

  const getAvatarUrl = (avatarPath) => {
    if (!avatarPath) return '/assets/images/default-avatar.png';
    const baseUrl = process.env.REACT_APP_API_URL;
    const filename = avatarPath.split('/').pop();
    return `${baseUrl}/uploads/${filename}`;
  };

  const getYoutubeVideoId = (url) => {
    const urlParams = new URLSearchParams(new URL(url).search);
    return urlParams.get('v');
  };

  return (
    <div className="container mt-4">
      <div className="px-4 py-5 my-5 text-center bg-light rounded-3">
        <h1 className="display-5 fw-bold text-body-emphasis">Discover gems through people like you.</h1>
        <div className="col-lg-8 mx-auto">
          <p className="lead mb-4">
            Share your playlist, get matched with like-minded music lovers, and join epic battles!
          </p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <Link to="/register" className="btn btn-primary btn-lg px-4 gap-3">Get Started</Link>
            <Link to="/login" className="btn btn-outline-secondary btn-lg px-4">Login</Link>
          </div>
        </div>
      </div>

      <div className="container px-4 py-5" id="featured-3">
        <h2 className="pb-2 border-bottom">How It Works</h2>
        <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
          <div className="feature col">
           
            <h2>1. Share Your Playlist</h2>
            <p>During registration, share your favorite playlist. We'll analyze your unique music taste to find your perfect matches.</p>
          </div>
          <div className="feature col">
           
            <h2>2. Get Matched</h2>
            <p>We'll find users with similar music preferences and suggest battles that align with your taste profile.</p>
          </div>
          <div className="feature col">
           
            <h2>3. Battle It Out</h2>
            <p>Join music battles with tracks that match your taste profile and compete with others in epic showdowns.</p>
          </div>
        </div>
      </div>

      {message && <p className="text-danger text-center">{message}</p>}

      <div className="mt-4">
        <h3 className="mb-3">Live Matches</h3>
        {liveMatches.length > 0 ? (
          <div>
            {liveMatches.slice(0, 3).map((match) => (
              <div key={match._id} className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <span className="badge bg-danger text-white rounded-pill px-3">Live</span>
                </div>
                <div className="card-body">
                  <div className="row g-3">
                    {match.urls.map((urlObj, index) => (
                      <div key={index} className="col-md-6 text-center">
                        <img src={urlObj.thumbnail} alt="Thumbnail" className="img-fluid mb-2 rounded" />
                        <p className="mb-2">{urlObj.title}</p>
                        <div className="d-flex justify-content-center align-items-center mb-2">
                          <img src={getAvatarUrl(urlObj.avatar)} alt="User Avatar" className="rounded-circle me-2" width="36" height="36" />
                          <p className="mb-0"><Link to={`/profile/${urlObj.userId._id}`}>{urlObj.username || 'Unknown'}</Link></p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <p className="text-center my-3 fw-bold">Score: {match.votes[0]} - {match.votes[1]}</p>
                  <p className="text-center mb-3 fw-bold">{formatTimeLeft(match.votingEndsAt)}</p>
                  <div className="text-center">
                    <a href={`/vote/${match._id}`} className="btn btn-primary">Vote Now</a>
                  </div>
                </div>
              </div>
            ))}
            {liveMatches.length > 3 && (
              <div className="text-center mt-4">
                <a href="/browse" className="btn btn-secondary">View All Live Matches</a>
              </div>
            )}
          </div>
        ) : (
          <div className="card mb-4">
            <div className="card-body text-center py-5">
              <h5 className="card-title mb-3">No Live Matches Available</h5>
              <p className="card-text mb-3">There are currently no live matches. Check back soon or start your own match!</p>
              <Link to="/share" className="btn btn-secondary">Start a Match</Link>
            </div>
          </div>
        )}
      </div>

      <div className="mt-4">
        <h3 className="mb-3">Open Matches</h3>
        {openMatches.length > 0 ? (
          <div>
            {openMatches.slice(0, 3).map((match) => (
              <div key={match._id} className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <span className="badge bg-warning text-dark rounded-pill px-3">Open</span>
                </div>
                <div className="card-body">
                  <div className="row g-3">
                    {match.urls.map((urlObj, index) => (
                      <div key={index} className="col-md-6 text-center">
                        <div className="embed-responsive embed-responsive-16by9 mb-2">
                          <iframe
                            className="embed-responsive-item"
                            src={`https://www.youtube.com/embed/${getYoutubeVideoId(urlObj.url)}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                        <p className="mb-2">{urlObj.title}</p>
                        <div className="d-flex justify-content-center align-items-center mb-2">
                          <img src={getAvatarUrl(urlObj.avatar)} alt="User Avatar" className="rounded-circle me-2" width="36" height="36" />
                          <p className="mb-0"><Link to={`/profile/${urlObj.userId._id}`}>{urlObj.username || 'Unknown'}</Link></p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="text-center mt-3">
                    <a href={`/battle/${match._id}`} className="btn btn-primary">Join Match</a>
                  </div>
                </div>
              </div>
            ))}
            {openMatches.length > 3 && (
              <div className="text-center mt-4">
                <a href="/browse" className="btn btn-secondary">View All Open Matches</a>
              </div>
            )}
          </div>
        ) : (
          <div className="card mb-4">
            <div className="card-body text-center py-5">
              <h5 className="card-title mb-3">No Open Matches Available</h5>
              <p className="card-text mb-3">There are currently no open matches. Be the first to start one!</p>
              <Link to="/share" className="btn btn-primary">Create a Match</Link>
            </div>
          </div>
        )}
      </div>

      <div className="mt-4">
        <h3 className="mb-3">Past Matches</h3>
        {pastMatches.length > 0 ? (
          <div>
            {pastMatches.slice(0, 3).map((match) => (
              <div key={match._id} className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <span className="badge bg-secondary text-white rounded-pill px-3">Finished</span>
                </div>
                <div className="card-body">
                  <div className="row g-3">
                    {match.urls.map((urlObj, index) => (
                      <div key={index} className="col-md-6 text-center">
                        <img src={urlObj.thumbnail} alt="Thumbnail" className="img-fluid mb-2 rounded" />
                        <p className="mb-2">{urlObj.title}</p>
                        <div className="d-flex justify-content-center align-items-center mb-2">
                          <img src={getAvatarUrl(urlObj.avatar)} alt="User Avatar" className="rounded-circle me-2" width="36" height="36" />
                          <p className="mb-0"><Link to={`/profile/${urlObj.userId._id}`}>{urlObj.username || 'Unknown'}</Link></p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <p className="text-center my-3 fw-bold">Score: {match.votes[0]} - {match.votes[1]}</p>
                  <div className="text-center">
                    <Link to={`/result/${match._id}`} className="btn btn-primary">View Match</Link>
                  </div>
                </div>
              </div>
            ))}
            {pastMatches.length > 3 && (
              <div className="text-center mt-4">
                <a href="/browse" className="btn btn-secondary">View All Past Matches</a>
              </div>
            )}
          </div>
        ) : (
          <div className="card mb-4">
            <div className="card-body text-center py-5">
              <h5 className="card-title mb-3">No Past Matches Available</h5>
              <p className="card-text mb-3">There are no completed matches yet. Start a match and make history!</p>
              <Link to="/share" className="btn btn-primary">Start the First Match</Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LandingPage;