import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Correct import
import './Profile.css';

const Profile = ({ token }) => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [battles, setBattles] = useState([]);
  const [error, setError] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loggedInUserId, setLoggedInUserId] = useState(null);
  const [liveMatches, setLiveMatches] = useState([]);
  const [openMatches, setOpenMatches] = useState([]);
  const [pastMatches, setPastMatches] = useState([]);

  useEffect(() => {
    const fetchProfile = async () => {
      if (!token) {
        setLoading(false);
        return;
      }

      try {
        const decodedToken = jwtDecode(token);
        setLoggedInUserId(decodedToken.id);

        const profileId = userId || decodedToken.id;

        if (!userId) {
          navigate(`/profile/${profileId}`, { replace: true });
          return; // Exit the effect to prevent double fetching
        }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/profile/${profileId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log('Profile response:', response.data);
        setUser(response.data.user);
        setBattles(response.data.battles);
        console.log('Battles:', response.data.battles);
        setError('');

        // Categorize battles
        const live = response.data.battles.filter(battle => battle.status === 'live');
        const open = response.data.battles.filter(battle => battle.status === 'open');
        const past = response.data.battles.filter(battle => battle.status === 'past');
        
        setLiveMatches(live);
        setOpenMatches(open);
        setPastMatches(past);

        console.log('Live matches:', live);
        console.log('Open matches:', open);
        console.log('Past matches:', past);
      } catch (error) {
        setError(`Error fetching profile: ${error.response?.data || error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [userId, token, navigate]);

  const handleAvatarUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('avatar', avatar);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload-avatar`, formData, {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
      });
      setUser({ ...user, avatar: response.data.avatar });
    } catch (error) {
      setError('Error uploading avatar');
    }
  };

  const renderMatchesList = (matches, title) => {
    console.log(`Rendering ${title}:`, matches);
    return (
      <div className="mt-4">
        <h3 className="mb-3">{title}</h3>
        {matches.length > 0 ? (
          <div>
            {matches.map((match) => (
              <div key={match._id} className="card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <span className={`badge ${match.status === 'live' ? 'bg-danger' : match.status === 'open' ? 'bg-warning' : 'bg-secondary'} text-white rounded-pill px-3`}>
                    {match.status.charAt(0).toUpperCase() + match.status.slice(1)}
                  </span>
                </div>
                <div className="card-body">
                  <div className="row g-3">
                    {match.urls.map((urlObj, index) => (
                      <div key={index} className="col-md-6 text-center">
                        <img src={urlObj.thumbnail} alt="Thumbnail" className="img-fluid mb-2" />
                        <p className="mb-2">{urlObj.title}</p>
                        <div className="d-flex justify-content-center align-items-center mb-2">
                          <img src={urlObj.avatar ? `${process.env.REACT_APP_API_URL}${urlObj.avatar}` : '/assets/images/default-avatar.png'} alt="User Avatar" className="rounded-circle me-2" width="36" height="36" />
                          <p className="mb-0"><Link to={`/profile/${urlObj.userId}`}>{urlObj.username || 'Unknown'}</Link></p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <p className="text-center my-3 fw-bold">Score: {match.votes[0]} - {match.votes[1]}</p>
                  <div className="text-center">
                    <Link to={`/result/${match._id}`} className="btn btn-primary">View Match</Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="card mb-4">
            <div className="card-body text-center py-5">
              <h5 className="card-title mb-3">No {title}</h5>
              <p className="card-text mb-3">
                {title === "Live Matches" && "There are currently no live matches."}
                {title === "Open Matches" && "There are currently no open matches."}
                {title === "Past Matches" && "You haven't participated in any matches yet."}
              </p>
              
            </div>
          </div>
        )}
      </div>
    );
  };

  if (loading) {
    return <div>Loading...</div>; // Show loading indicator while fetching data
  }

  if (!token) {
    return (
      <div className="container mt-5">
        <div className="alert alert-warning" role="alert">
          <h4 className="alert-heading">Login Required</h4>
          <p>You must be logged in to view user profiles. Please log in or create an account to continue.</p>
          <hr />
          <p className="mb-0">
            <Link to="/login" className="btn btn-primary me-2">Login</Link>
            <Link to="/signup" className="btn btn-secondary">Sign Up</Link>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-3 mt-md-5 px-3 px-md-0">
      {error && <div className="alert alert-danger">{error}</div>}
      {user ? (
        <div>
          <h2 className="mb-4">{user.username}'s Profile</h2>
          <div className="card mb-4">
            <div className="card-body p-3 p-md-4">
              <div className="row g-3">
                <div className="col-md-4">
                  <img src={user.avatar ? `${process.env.REACT_APP_API_URL}${user.avatar}` : '/assets/images/default-avatar.png'} alt="User Avatar" className="img-thumbnail rounded-circle profile-avatar mb-3" style={{ width: '86px', height: '86px' }} />
                  <p className="mb-3">Username: {user.username}</p>
                  <ul className="list-group mb-3">
                    <li className="list-group-item">Points: {user.points}</li>
                    <li className="list-group-item">Wins: {user.wins}</li>
                    <li className="list-group-item">Losses: {user.losses}</li>
                    <li className="list-group-item">Draws: {user.draws}</li>
                  </ul>
                  {loggedInUserId === userId && (
                    <form onSubmit={handleAvatarUpload}>
                      <div className="mb-3">
                        <input type="file" onChange={(e) => setAvatar(e.target.files[0])} className="form-control" />
                      </div>
                      <button type="submit" className="btn btn-primary">Upload Avatar</button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
          {renderMatchesList(liveMatches, "Live Matches")}
          {renderMatchesList(openMatches, "Open Matches")}
          {renderMatchesList(pastMatches, "Past Matches")}
        </div>
      ) : (
        <div>Loading profile...</div>
      )}
    </div>
  );
};

export default Profile;