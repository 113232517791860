import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import axios from 'axios'; // Added this line to import axios
import LandingPage from './components/LandingPage';
import ShareSong from './components/ShareSong';
import BrowseBattles from './components/BrowseBattles';
import JoinBattle from './components/JoinBattle';
import VotingPage from './components/VotingPage';
import BattleResult from './components/BattleResult';
import Feedback from './components/Feedback';
import Register from './components/Register';
import Login from './components/Login';
import NavBar from './components/NavBar';
import Profile from './components/Profile';
import Leaderboard from './components/Leaderboard';
import Notifications from './components/Notifications';
import { jwtDecode } from 'jwt-decode';

const App = () => {
  const [token, setToken] = useState('');
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(true);
  const [unreadCount, setUnreadCount] = useState(0);

  const handleLogin = (newToken, newUsername) => {
    setToken(newToken);
    setUsername(newUsername);
    localStorage.setItem('token', newToken);
    localStorage.setItem('username', newUsername);
    
    // Decode the token to get the userId
    try {
      const decodedToken = jwtDecode(newToken);
      if (decodedToken.id) {
        setUserId(decodedToken.id);
        localStorage.setItem('userId', decodedToken.id);
      }
    } catch (error) {
      console.error('Failed to decode token:', error);
    }
  };

  useEffect(() => {
    console.log('useEffect for token retrieval'); // Debugging line
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      try {
        const decodedToken = jwtDecode(storedToken);
        console.log('Decoded token:', decodedToken); // Debugging line
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          console.log('Token has expired');
          localStorage.removeItem('token');
          localStorage.removeItem('username');
        } else {
          setToken(storedToken);
          if (decodedToken.id) {
            setUserId(decodedToken.id); // Set userId from the decoded token
            console.log('Decoded userId:', decodedToken.id); // Debugging line
          } else {
            console.error('Decoded token does not contain id');
          }
        }
      } catch (error) {
        console.error('Failed to decode token:', error);
      }
    }
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }
    setLoading(false); // Set loading to false after token retrieval
  }, []);

  useEffect(() => {
    console.log('useEffect for username and userId'); // Debugging line
    console.log('Current username:', username);
    console.log('Stored Token:', localStorage.getItem('token'));
    console.log('Current userId:', userId); // Debugging line
  }, [username, userId]);

  useEffect(() => {
    const fetchUnreadCount = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/notifications/count`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUnreadCount(response.data.count);
      } catch (err) {
        console.error('Error fetching unread notifications count:', err);
      }
    };

    if (token) {
      fetchUnreadCount();
    }
  }, [token]);

  const handleLogout = () => {
    setToken('');
    setUsername('');
    setUserId('');
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    window.location.href = '/login';
  };

  if (loading) {
    return <div>Loading...</div>; // Show loading indicator while retrieving token
  }

  return (
    <Router>
      <div>
        <NavBar token={token} handleLogout={handleLogout} username={username} userId={userId} unreadCount={unreadCount} />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/share" element={<ShareSong token={token} />} />
          <Route path="/browse" element={<BrowseBattles />} />
          <Route path="/battle/:id" element={<JoinBattle token={token} />} />
          <Route path="/vote/:id" element={<VotingPage token={token} />} />
          <Route path="/result/:id" element={<BattleResult />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/feedback/:id" element={<Feedback token={token} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login setToken={setToken} setUsername={setUsername} handleLogin={handleLogin} />} />
          <Route path="/profile" element={<Profile token={token} />} />
          <Route path="/profile/:userId" element={<Profile token={token} />} />
          <Route path="/notifications" element={<Notifications token={token} setUnreadCount={setUnreadCount} />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;