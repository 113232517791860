// BattleResult.js
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import ResultModal from './ResultModal';

const BattleResult = () => {
  const { id } = useParams();
  const [battle, setBattle] = useState(null);
  const [showResultModal, setShowResultModal] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!token) {
      setIsLoading(false);
      return;
    }

    const fetchBattle = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/battle/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setBattle(response.data);
      } catch (error) {
        console.error('Error fetching battle', error);
      }
    };

    const checkBattleResult = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/latest-battle-result`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        if (response.data.message) {
          setResultMessage(response.data.message);
          setShowResultModal(true);
        }
      } catch (error) {
        console.error('Error fetching battle result:', error);
      }
    };

    fetchBattle();
    checkBattleResult();
    setIsLoading(false);
  }, [id, token]);

  if (isLoading) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  if (!token) {
    return (
      <div className="container mt-5">
        <div className="alert alert-warning" role="alert">
          <h4 className="alert-heading">Login Required</h4>
          <p>You must be logged in to view battle results. Please log in or create an account to continue.</p>
          <hr />
          <p className="mb-0">
            <Link to="/login" className="btn btn-primary me-2">Login</Link>
            <Link to="/signup" className="btn btn-secondary">Sign Up</Link>
          </p>
        </div>
      </div>
    );
  }

  if (!battle) return <div className="text-center mt-5">No battle data available.</div>;

  const winnerIndex = battle.votes[0] > battle.votes[1] ? 0 : 1;
  const winner = battle.urls[winnerIndex];

  return (
    <div className="container mt-3 mt-md-5 px-3 px-md-0">
      <h1 className="text-center mb-4">Match result:</h1>
      <div className="card mb-4">
        <div className="card-body p-3 p-md-4">
          <div className="row g-3">
            {battle.urls.map((urlObj, index) => (
              <div key={index} className="col-md-6 text-center">
                <div className="embed-responsive embed-responsive-16by9 mb-3">
                  <iframe
                    className="embed-responsive-item"
                    src={`https://www.youtube.com/embed/${urlObj.url.split('v=')[1]}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
                <p className="mb-2">{urlObj.title}</p>
                <div className="d-flex justify-content-center align-items-center mb-3">
                  <img src={urlObj.avatar ? `${process.env.REACT_APP_API_URL}${urlObj.avatar}` : 'https://via.placeholder.com/50'} alt="User Avatar" className="rounded-circle me-2" width="36" height="36" />
                  <p className="mb-0">by: <a href={`/profile/${urlObj.userId._id}`}>{urlObj.username || 'Unknown'}</a></p>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center mt-4">
            <h3 className="mb-3">Winner: {winner.username || 'Unknown'}</h3>
            <p className="mb-0">Final Score: {battle.votes[0]} - {battle.votes[1]}</p>
          </div>
        </div>
      </div>
      <ResultModal 
        show={showResultModal} 
        onHide={() => setShowResultModal(false)} 
        message={resultMessage} 
      />
    </div>
  );
};

export default BattleResult;