import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';

const getYoutubeVideoId = (url) => {
  const urlParams = new URLSearchParams(new URL(url).search);
  return urlParams.get('v');
};

const JoinBattle = ({ token }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [url, setUrl] = useState('');
  const [message, setMessage] = useState('');
  const [battle, setBattle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!token) {
      setMessage('You must be logged in to join a battle');
      setLoading(false);
      return;
    }
    const fetchBattle = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/battle/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setBattle(response.data);
        setMessage('');
      } catch (error) {
        setMessage('Error fetching battle details');
      } finally {
        setLoading(false);
      }
    };
    fetchBattle();
  }, [id, token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/battle/${id}`,
        { url },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMessage('Successfully joined the battle');
      setShowModal(true);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        if (error.response.data === 'User already joined this battle') {
          setMessage('You have already joined this battle');
        } else if (error.response.data === 'Battle already full') {
          setMessage('Battle already full');
        } else if (error.response.data === 'You cannot join your own battle') {
          setMessage('You cannot join your own battle');
        } else {
          setMessage(error.response.data);
        }
      } else {
        setMessage(`Error joining battle: ${error.message}`);
      }
    }
  };

  const handleInviteFriend = () => {
    const battleLink = `${window.location.origin}/battle/${id}`;
    navigator.clipboard.writeText(battleLink)
      .then(() => {
        alert('Battle link copied to clipboard! Now share it with a friend and see who wins!');
      })
      .catch(err => {
        console.error('Error copying link to clipboard:', err);
      });
  };

  const handleGoToVoting = () => {
    navigate(`/vote/${id}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!token) {
    return (
      <div className="container mt-5">
        <div className="alert alert-warning" role="alert">
          <h4 className="alert-heading">Login Required</h4>
          <p>You must be logged in to join a battle. Please log in or create an account to continue.</p>
          <hr />
          <p className="mb-0">
            <Link to="/login" className="btn btn-primary me-2">Login</Link>
            <Link to="/signup" className="btn btn-secondary">Sign Up</Link>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-3 mt-md-5 px-3 px-md-0">
      <div className="p-4 p-md-5 text-center bg-light rounded-3 mb-4">
        <h1 className="text-body-emphasis mb-3">Join match!</h1>
        <p className="lead mb-4">Ready to compete? Share your track and see how it stands against others in this musical showdown!</p>
        <p className="text-muted">Vote, invite friends, and may the best track win!</p>
      </div>

      {battle && (
        <div className="card mb-4">
          <div className="card-body p-3 p-md-4">
            <form onSubmit={handleSubmit} className="needs-validation mb-4" noValidate>
              <div className="form-group mb-3">
                <label htmlFor="url">YouTube URL</label>
                <input
                  type="text"
                  className="form-control"
                  id="url"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="Enter YouTube URL"
                  required
                />
                <div className="invalid-feedback">
                  Please enter a valid YouTube URL.
                </div>
              </div>
              {message && <div className="alert alert-info mt-3">{message}</div>}
              <button type="submit" className="btn btn-primary">Join Battle</button>
            </form>
            {battle.urls.map((urlObj, index) => (
              <div key={index} className="mb-4">
                <div className="embed-responsive embed-responsive-16by9 mb-3">
                  <iframe
                    className="embed-responsive-item"
                    src={`https://www.youtube.com/embed/${getYoutubeVideoId(urlObj.url)}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="d-flex align-items-center">
                  <img src={urlObj.avatar ? `${process.env.REACT_APP_API_URL}${urlObj.avatar}` : 'https://via.placeholder.com/50'} alt="User Avatar" className="rounded-circle me-2" width="36" height="36"/>
                  <p className="mb-0">by: <Link to={`/profile/${urlObj.userId._id}`}>{urlObj.userId ? urlObj.userId.username : 'Unknown'}</Link></p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <div className="modal-content rounded-4 shadow">
          <div className="modal-header border-bottom-0">
            <h1 className="modal-title fs-5">Match joined!</h1>
            <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
          </div>
          <div className="modal-body py-0">
            <p>Cast your vote and see if your favorite track wins.</p>
          </div>
          <div className="modal-footer flex-column align-items-stretch w-100 gap-2 pb-3 border-top-0">
            <Button variant="primary" onClick={handleInviteFriend} className="btn-lg">
              Share with a friend
            </Button>
            <Button variant="secondary" onClick={handleGoToVoting} className="btn-lg">
              Go to Voting Page
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default JoinBattle;