import React, { useState } from 'react';
import axios from 'axios';

const Feedback = ({ match, token }) => {
  const [comment, setComment] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const config = { headers: { Authorization: `Bearer ${token}` } };
      await axios.post(`${process.env.REACT_APP_API_URL}/feedback/${match.params.id}`, { comment }, config);
      alert('Feedback submitted successfully');
      setComment('');
    } catch (err) {
      console.error('Error submitting feedback:', err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <textarea placeholder="Leave your comment" value={comment} onChange={(e) => setComment(e.target.value)} required></textarea>
      <button type="submit">Submit</button>
    </form>
  );
};

export default Feedback;