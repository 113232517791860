import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';

const Leaderboard = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');

  const fetchLeaderboard = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/leaderboard`);
      setUsers(response.data);
      setError('');
    } catch (err) {
      setError('Failed to fetch leaderboard');
    }
  }, []);

  useEffect(() => {
    fetchLeaderboard();
    const intervalId = setInterval(fetchLeaderboard, 30000); // Refresh every 30 seconds
    return () => clearInterval(intervalId);
  }, [fetchLeaderboard]);

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Leaderboard</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      <ul className="list-group">
        {users.map((user, index) => (
          <li key={user._id} className="list-group-item d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <img src={user.avatar ? `${process.env.REACT_APP_API_URL}${user.avatar}` : 'https://via.placeholder.com/50'} alt="User Avatar" className="rounded-circle mr-2" width="36" height="36" />
              <span>{index + 1}. {user.username}</span>
            </div>
            <span className="badge rounded-pill bg-secondary">{user.points} points</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Leaderboard;