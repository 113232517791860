import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom'; // Add Link here

const VotingPage = ({ token }) => {
  const { id } = useParams();
  const [battle, setBattle] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState(''); // Add state for success message

  const getYoutubeVideoId = (url) => {
    const urlParams = new URLSearchParams(new URL(url).search);
    return urlParams.get('v');
  };

  useEffect(() => {
    console.log('REACT_APP_API_URL in VotingPage:', process.env.REACT_APP_API_URL);
    console.log('Full URL being called:', `${process.env.REACT_APP_API_URL}/api/battle/${id}`);

    if (!token) {
      setError('You need to be logged in to view this page.');
      setLoading(false);
      return;
    }

    const fetchBattle = async () => {
      try {
        console.log('Fetching from:', `${process.env.REACT_APP_API_URL}/api/battle/${id}`);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/battle/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('Battle data:', response.data);
        setBattle(response.data);
        setError(''); // Clear the error message if token is valid
      } catch (error) {
        console.error('Error fetching battle:', error.response || error);
        setError('Error fetching battle details');
      } finally {
        setLoading(false);
      }
    };

    fetchBattle();
  }, [id, token]);

  const handleVote = async (voteIndex) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/vote/${id}`,
        { voteIndex },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccessMessage('Vote submitted successfully');
      // Update the local state to reflect the new vote count from the server
      setBattle((prevBattle) => ({
        ...prevBattle,
        votes: response.data.votes || prevBattle.votes
      }));
    } catch (error) {
      console.error('Error submitting vote:', error.response || error);
      setError(error.response?.data?.message || 'Error submitting vote');
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Show loading indicator while fetching data
  }

  return (
    <div className="container mt-3 mt-md-5 px-3 px-md-0">
      {error && <div className="alert alert-danger">{error}</div>}
      {successMessage && <div className="alert alert-success">{successMessage}</div>}
      {battle ? (
        <div className="card">
          <div className="card-body p-3 p-md-4">
            <h2 className="card-title text-center mb-4">Who's winning this match?</h2>
            <div className="row g-3">
              {battle.urls.map((urlObj, index) => (
                <div key={index} className="col-md-6 mb-4">
                  <div className="d-flex justify-content-center mb-3">
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe
                        className="embed-responsive-item"
                        src={`https://www.youtube.com/embed/${getYoutubeVideoId(urlObj.url)}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                  <p className="text-center mb-2">{urlObj.title}</p>
                  <div className="d-flex justify-content-center align-items-center mb-2">
                    <img src={urlObj.avatar ? `${process.env.REACT_APP_API_URL}${urlObj.avatar}` : 'https://via.placeholder.com/50'} alt="User Avatar" className="rounded-circle me-2" width="36" height="36"/>
                    <p className="mb-0">by: <Link to={`/profile/${urlObj.userId._id}`}>{urlObj.userId ? urlObj.userId.username : 'Unknown'}</Link></p>
                  </div>
                  <p className="text-center mb-3"><strong>Votes:</strong> {battle.votes[index]}</p>
                  <div className="d-flex justify-content-center">
                    <button onClick={() => handleVote(index)} className="btn btn-primary">Vote</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <p>Loading battle...</p>
      )}
    </div>
  );
};

export default VotingPage;