import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Notifications = ({ token, setUnreadCount }) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/notifications`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log('Fetched notifications:', response.data);
        setNotifications(response.data);
      } catch (err) {
        console.error('Error fetching notifications:', err);
      }
    };

    const markNotificationsAsRead = async () => {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/notifications/mark-as-read`, {}, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUnreadCount(0);
      } catch (err) {
        console.error('Error marking notifications as read:', err);
      }
    };

    fetchNotifications();
    markNotificationsAsRead();
  }, [token, setUnreadCount]);

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Notifications</h2>
      <ul className="list-group">
        {notifications.map((notification) => (
          <li key={notification._id} className="list-group-item">
            {notification.message}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Notifications;