import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const NavBar = ({ token, handleLogout, username, userId }) => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [userPoints, setUserPoints] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const fetchUserData = async () => {
    if (token && userId) {
      try {
        const [notificationsResponse, pointsResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/api/notifications/count`, {
            headers: { Authorization: `Bearer ${token}` }
          }),
          axios.get(`${process.env.REACT_APP_API_URL}/api/users/${userId}/points`, {
            headers: { Authorization: `Bearer ${token}` }
          })
        ]);
        setUnreadCount(notificationsResponse.data.count);
        setUserPoints(pointsResponse.data.points);
      } catch (err) {
        console.error('Error fetching user data:', err);
      }
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [token, userId]);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <Link className="navbar-brand" to="/">Whohears</Link>
        <button className="navbar-toggler" type="button" onClick={toggleNavbar}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/browse">Browse Matches</Link>
            </li>
            {token ? (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/share">Start Match</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/leaderboard">Leaderboard</Link>
                </li>
                <li className="nav-item d-flex align-items-center">
                  <Link to={`/profile/${userId}`} className="nav-link">
                    Welcome, <strong>{username}</strong>
                  </Link>
                </li>
                <li className="nav-item">
                  <button className="btn btn-outline-secondary" onClick={handleLogout}>Logout</button>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/login">Login</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/register">Register</Link>
                </li>
              </>
            )}
          </ul>
          {token && (
            <div className="ms-auto d-flex align-items-center">
              <span className="me-3">
                <span className="badge bg-primary rounded-pill">
                  {userPoints} points
                </span>
              </span>
              <button type="button" className="btn btn-link position-relative" onClick={() => window.location.href = '/notifications'}>
                <i className="fas fa-bell"></i>
                {unreadCount > 0 && (
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white">
                    {unreadCount}
                    <span className="visually-hidden"></span>
                  </span>
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;